export default {
	data: () => ({
		department: {
			id: 0,
			name: "",
			description: ""
		},
    loading: false,
		backUrl: "/department"
	}),
	methods: {
		resetForm() {
			this.$refs.observer.reset();
			this.department = {
				id: 0,
				name: "",
				description: "",
			};
		},
		validateDepartment() {
			this.department.id > 0 ? this.update() : this.add();
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 10000);
		},
		update() {
			let _vm = this;
			this.axios
				.post("/department/" + this.department.id, {
					...this.department,
					_method: "PATCH"
				})
				.then(function (response) {
					_vm.department = response.data.data;
					_vm.$router.push(_vm.backUrl);
				})
				.catch(function () { });
		},
		add() {
			let _vm = this;
			this.axios
				.post("/department", _vm.department)
				.then(function () {
					_vm.$router.push(_vm.backUrl);
				})
				.catch(function () { });
		},
		getDetail() {
			let _vm = this;
			this.axios
				.get("/department/" + this.$route.params.id)
				.then(function (response) {
					_vm.department = response.data.data;
				})
				.catch(function () { });
		}
	},
	mounted() {
		if (this.$route.params.id > 0) {
			this.getDetail();
		}
	}
};

